import { Navigate, Outlet, useRoutes } from 'react-router-dom';

import DashboardLayout from './layouts/dashboard/DashboardLayout';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

import { RouteAdminDashboard, RouteLandingPage } from './config/routes';

import Logout from './pages/Logout';

import DashboardAdmin from './pages/admin/DashboardAdmin';
import Login from './pages/common/Login';
import PageError from './pages/admin/PageError';
import PastPayments from './pages/admin/PastPayments';
import Players from './pages/admin/Players';

export default function Router() {
    return useRoutes([
        /*
        Admin
        */
        {
            path: RouteLandingPage,
            element: <Outlet />,
            children: [
                {
                    path: 'login',
                    element: <LogoOnlyLayout loginPage />,
                    children: [{ path: '', element: <Login admin /> }]
                },

                {
                    path: '',
                    element: <DashboardLayout admin />,
                    children: [
                        { path: '', element: <Navigate to={RouteAdminDashboard} replace /> },
                        {
                            path: 'payouts',
                            element: <DashboardAdmin />
                        },
                        {
                            path: 'past',
                            element: <PastPayments />
                        },
                        {
                            path: 'players',
                            element: <Players />
                        }

                    ]
                }
            ]
        },

        {
            path: RouteLandingPage,
            element: <LogoOnlyLayout />,
            children: [
                { path: 'logout', element: <Logout /> },
                { path: '404', element: <PageError e404 /> },
                { path: '401', element: <PageError /> }
            ]
        },

        { path: '*', element: <Navigate to={RouteAdminDashboard} replace /> }
    ]);
}
