/*
    Imports
*/
import { Icon } from '@iconify/react';
import {
    Alert,
    Collapse,
    IconButton,
    InputAdornment,
    Link,
    Stack,
    TextField
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { LoginSchema } from 'src/config/form-schemas';
import { HidePasswordIcon, PasswordIcon, ShowPasswordIcon, UsersIcon } from 'src/config/icons';
import { RouteAdminDashboard, RouteAdminLogin } from 'src/config/routes';
import userService from 'src/services/UserService';
import palette from 'src/theme/palette';

/*
    Main Working
*/
export default ({ }) => {
    /*
      States, Params, Navigation, Query.
    */
    const [serverError, setServerError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    /*
      Form Setup
    */
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: LoginSchema,
        onSubmit: (values, { setSubmitting, setFieldError }) => {
            userService
                .login(values.email, values.password)
                .then((_data) => {
                    navigate(RouteAdminDashboard, { replace: true });
                })
                .catch((err) => {
                    console.error('Error', err, err.response);
                    setSubmitting(false);
                    if (!err.response) {
                        setServerError('Error occured please try later');
                    } else {
                        const { data } = err.response;
                        if (data?.statusCode == 404) setFieldError('email', 'User does not exist');
                        if (data?.statusCode == 401)
                            setFieldError('password', 'Incorrect password');
                    }
                });
        }
    });
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    /*
      Handlers
    */

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    /*
      Use Effect Hooks.
    */

    /*
      Main Design
    */
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        autoComplete="email"
                        type="text"
                        label="Email"
                        {...getFieldProps('email')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon icon={UsersIcon} inline="true" style={{ fontSize: 20 }} />
                                </InputAdornment>
                            )
                        }}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />

                    <br />

                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        {...getFieldProps('password')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleShowPassword} edge="end">
                                        <Icon
                                            icon={
                                                showPassword ? HidePasswordIcon : ShowPasswordIcon
                                            }
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon
                                        icon={PasswordIcon}
                                        inline="true"
                                        style={{ fontSize: 20 }}
                                    />
                                </InputAdornment>
                            )
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />
                </Stack>

                <br />

                <LoadingFormButton
                    style={{ padding: '0px 70px' }}
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    Login
                </LoadingFormButton>
                {serverError && (
                    <Stack sx={{ width: '50%' }} marginTop={3}>
                        <Collapse in={true}>
                            <Alert severity="error">{serverError}</Alert>
                        </Collapse>
                    </Stack>
                )}
            </Form>
        </FormikProvider>
    );
};
