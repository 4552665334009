export const RouteLandingPage = '/';
export const RouteSaloons = '/salons';
export const RouteOffers = '/offers';
export const RouteAbout = '/about';

export const RouteUserLogin = '/users/login';
export const RouteUserDashboard = '/users/sellers';
export const RoutePastAppointments = '/users/pastappointments';
export const RouteLogout = '/logout';
export const RouteUserRegister = '/users/register';

export const RouteAdminLogin = '/login';
export const RoutePastPayments = '/past';
export const RouteAdminDashboard = '/payouts';
export const RoutePlayers = '/players';
export const RouteAdminCategory = '/categories';
export const RouteAdminServices = '/services';
export const RouteAdminProducts = '/products';
export const RouteAdminRegister = '/register';

export const RouteSellerLogin = '/seller/login';
export const RouteSellerRegister = '/seller/register';
export const RouteSellerDashboard = '/seller/products';

export const Route401 = '/401';
