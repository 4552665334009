import axios from 'axios';
import { BackendURLAPI } from 'src/config/settings';

axios.defaults.baseURL = BackendURLAPI;
axios.defaults.withCredentials = true;
axios.defaults.headers['Content-Type'] = 'application/json; charset=utf-8' || 'application/json;';
class GenericService {
    tokenUpdate = () => {
        const token = localStorage.getItem('token');
        if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        else delete axios.defaults.headers.common.Authorization;
    };

    get = (url, data) =>
        new Promise((resolve, reject) => {
            axios
                .get(url, data)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    post = (url, data) =>
        new Promise((resolve, reject) => {
            axios
                .post(url, JSON.stringify(data))
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    console.warn(err);
                    reject(err);
                });
        });

    delete = (url) =>
        new Promise((resolve, reject) => {
            axios
                .delete(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
}
export default GenericService;
