/*
  Imports
*/
import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog from 'src/components/misc/alerts/Dialog';
import CenterLoading from 'src/components/misc/CenterLoading';

/*
  Imports:
    Our Imports
    Components and Settings
*/

import ListPageTitle from 'src/components/misc/ListPageTitle';
import paymentService from 'src/services/PaymentsService';

import Page from '../../components/Page';
import PaymentsList from './tables/PaymentsList';
/*
  Main Working
*/
export default () => {
    /*
    States, Params, Navigation, Query, Variables.
        */

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [stripeData, setStripeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [link, setLink] = useState();

    /*
  Handlers, Functions
    */
    const getData = () => {
        setLoading(true);
        paymentService
            .getAll()
            .then((u) => {
                setData(u);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => setLoading(false));
    };

    const handleCloseError = () => {
        setError(false);
        getData();
    };
    const pageName = 'Payouts';

    /*
        Use Effect Hooks.
    */

    useEffect(() => {
        getData();
    }, []);

    /*
    Main Design.
        */
    return (
        <Page title={pageName}>
            <Container>
                <ListPageTitle>{pageName}</ListPageTitle>
                {loading ? (
                    <CenterLoading />
                ) : (
                    <>
                        <PaymentsList
                            data={data}
                            getData={getData}
                            setLoading={setLoading}
                            setError={setError}
                        />
                        <br />
                        <br />
                    </>
                )}
                <Dialog
                    error
                    buttonText={'Close'}
                    openDialog={!!error}
                    handleButton={handleCloseError}
                >
                    {error}
                </Dialog>
            </Container>
        </Page>
    );
};
