import GenericService from './GenericService';

class UserService extends GenericService {
    login = (ID, Password) =>
        new Promise((resolve, reject) => {
            this.post(`auth/admin`, {
                email: ID,
                password: Password
            })
                .then((data) => {
                    sessionStorage.setItem('user', JSON.stringify(data));
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    logout = () => {
        sessionStorage.removeItem('user');
        return new Promise((resolve, reject) => {
            this.get('auth/logout')
                .then(() => {
                    sessionStorage.removeItem('user');
                    resolve();
                })
                .catch((err) => {
                    sessionStorage.removeItem('user');
                    reject(err);
                });
        });
    };
    getAll = () => this.get(`users`);

    isLoggedIn = async () =>
        new Promise((resolve, reject) => {
            this.get('/')
                .then((res) => {
                    const { loggedIn } = res;
                    if (loggedIn) return resolve();
                    sessionStorage.removeItem('user');
                    reject('Not logged in');
                })
                .catch((err) => {
                    reject(err);
                });
        });

    getLoggedInUser() {
        const sUser = sessionStorage.getItem('user');
        return JSON.parse(sUser);
    }

    getPlayers = () => this.get('users');
}

export default UserService;
