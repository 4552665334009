import { Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';

SearchNotFound.propTypes = {
    searchQuery: PropTypes.string
};

export default function SearchNotFound({ searchQuery = '', ...other }) {
    let description = `No results found for: '${searchQuery}'. Try checking for typos or using complete words.`;

    if (!searchQuery) description = 'No data found';
    return (
        <Paper {...other}>
            <Typography gutterBottom align="center" variant="subtitle1">
                Not found
            </Typography>
            <Typography variant="body2" align="center">
                {description}
            </Typography>
        </Paper>
    );
}
