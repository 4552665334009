/*
  Imports
*/
import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';

/*
  Imports:
    Our Imports
    Components and Settings
*/

import ListPageTitle from 'src/components/misc/ListPageTitle';
import paymentService from 'src/services/PaymentsService';

import Page from '../../components/Page';
import PastPaymentsList from './tables/PastPaymentsList';

/*
  Main Working
*/
export default () => {
    /*
  States, Params, Navigation, Query, Variables.
  */
    const navigate = useNavigate();

    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(true);

    /*
Handlers, Functions
*/
    const getData = () => {
        setLoading(true);
        paymentService
            .getPast()
            .then((u) => {
                setData(u);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => setLoading(false));
    };

    const pageName = 'Past Payments';

    /*
  Use Effect Hooks.
*/

    useEffect(() => {
        getData();
    }, []);

    /*
  Main Design.
  */
    return (
        <Page title={pageName}>
            <Container>
                <ListPageTitle>{pageName}</ListPageTitle>
                {loading ? (
                    <CenterLoading />
                ) : (
                    <>
                        <PastPaymentsList data={data ?? []} />
                        <br />
                        <br />
                    </>
                )}
            </Container>
        </Page>
    );
};
